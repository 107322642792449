import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import SignUpComponent from "../components/sign-up"

const VisibleAndVocalPage = ({ location }) => {
  return (
    <Layout>
      <SEO
        location={location}
        image="/images/visible-and-vocal.png"
        title="Visible & Vocal"
        description="On February 4th, 2021 POCAM presented Visible & Vocal - The Canadian BIPOC in Advertising and Marketing Study. View the presentation, and download the report here."
      />
      <section className="bg-dark text-white py-4 py-lg-5">
        <Container>
          <Row className="mb-4">
            <Col md="7">
              <h1 className="display-4 mb-3">Visible & Vocal</h1>
              <h2 className="mb-0">
                The Canadian BIPOC Advertising & Marketing Study
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4 py-lg-5">
        <Container>
          <Row>
            <Col sm="8">
              <p className="lead">
                POCAM (People of Colour in Advertising & Marketing) is pleased to present the 4th Annual Visible & Vocal study, the only industry study that focuses on the profile, perspectives, and experiences of Canadian BIPOC advertising and marketing professionals.
              </p>
              <p>
                POCAM hosted its first ever in person Townhall at <a href="https://niacentre.org/" rel="noreferrer" target="_blank">NIA Centre for the Arts</a>, Canada’s first black arts centre, to release the findings.
              </p>
              <p>
                For the second straight year the study was led by POCAM co-founder Julian Franklin and member Ken Gamage (Analytics/Research lead). Moderated by POCAM Secretary Joycelyn David, Ken and Julian shared the latest results to a passionately engaged audience comprised of members, media and allies.
              </p>
              <p>
                Download our latest study to learn more about BIPOC experiences, gathered in a study authored by BIPOC professionals and examine what actions are needed to advance and recognize BIPOC contributions in 2024.
              </p>
              <p>
                Because sometimes, being seen begins with being heard!
              </p>
            </Col>
            <Col className="text-md-right">
              <a
                className="btn btn-primary btn-lg mr-3"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1ZbkzhiS_JxDN0ad88VScFLCzSPhsWF_9/view?usp=sharing"
                target="_blank"
              >
                Download the presentation
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <SignUpComponent />
    </Layout>
  )
}

export default VisibleAndVocalPage
